{
  "name": "spot",
  "version": "0.1.23",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build && npm run sentry:sourcemaps && node ./scripts/post-build.js",
    "build:production": "ng build --configuration production",
    "build:staging": "ng build --configuration staging && node ./scripts/post-build.js",
    "build:development": "ng build --configuration development",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "prettier:format": "npx prettier . --write",
    "prettier:check": "npx prettier . --check",
    "release": "release-it",
    "supabase:sync-types": "npx supabase@latest gen types typescript --project-id \"hbzvqlygeugkryvyoxdk\" --schema public,stripe > src/app/core/types/supabase.ts",
    "analyze-bundle": "ng build --configuration=analyze-bundle && source-map-explorer dist/**/*.js --no-border-checks",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org solid-code-sl --project spot ./dist && sentry-cli sourcemaps upload --org solid-code-sl --project spot ./dist"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.3",
    "@angular/cdk": "^18.1.3",
    "@angular/common": "^18.1.3",
    "@angular/compiler": "^18.1.3",
    "@angular/core": "^18.1.3",
    "@angular/forms": "^18.1.3",
    "@angular/platform-browser": "^18.1.3",
    "@angular/platform-browser-dynamic": "^18.1.3",
    "@angular/router": "^18.1.3",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@ng-icons/core": "^29.3.0",
    "@ng-icons/huge-icons": "^29.5.0",
    "@ng-icons/iconsax": "^29.3.0",
    "@ng-icons/tabler-icons": "^29.3.0",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@ngx-popovers/popover": "^18.2.0",
    "@perfectmemory/ngx-contextmenu": "^18.0.0",
    "@sentry/angular": "^8.35.0",
    "@sentry/cli": "^2.38.0",
    "@supabase/supabase-js": "^2.45.0",
    "@tolgee/core": "^5.29.5",
    "@tolgee/ngx": "^5.29.5",
    "@worktile/gantt": "^18.0.0",
    "angular-gridster2": "^18.0.1",
    "angular-svg-icon": "^18.0.1",
    "chart.js": "^4.4.4",
    "filesize": "^9.0.11",
    "highlight.js": "^11.10.0",
    "html2canvas": "^1.4.1",
    "humanize-duration": "^3.32.1",
    "lottie-web": "^5.12.2",
    "luxon": "^3.5.0",
    "ng-zorro-antd": "^18.1.0",
    "ng2-charts": "^6.0.1",
    "ngx-clipboard": "^16.0.0",
    "ngx-filesize": "^3.0.4",
    "ngx-guided-tour": "^2.0.1",
    "ngx-image-cropper": "^8.0.0",
    "ngx-lottie": "^12.0.0",
    "ngx-permissions": "^17.1.0",
    "ngx-quill": "^26.0.5",
    "ngx-quill-upload": "^2.0.0",
    "quill": "^2.0.2",
    "quill-magic-url": "^4.2.0",
    "quill-mention": "^6.0.1",
    "rxjs": "~7.8.1",
    "smart-array-filter": "^4.0.2",
    "tslib": "^2.6.3",
    "uuid": "^11.0.3",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.3",
    "@angular/cli": "^18.1.3",
    "@angular/compiler-cli": "^18.1.3",
    "@tailwindcss/aspect-ratio": "^0.4.2",
    "@tailwindcss/container-queries": "^0.1.1",
    "@types/humanize-duration": "^3.27.4",
    "@types/jasmine": "~5.1.0",
    "@types/luxon": "^3.4.2",
    "autoprefixer": "^10.4.19",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "prettier": "3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.3",
    "release-it": "^17.6.0",
    "source-map-explorer": "^2.5.3",
    "supabase": "^1.190.0",
    "tailwind-scrollbar": "^3.1.0",
    "tailwindcss": "^3.4.4",
    "tailwindcss-animate": "^1.0.7",
    "typescript": "~5.4.2"
  },
  "overrides": {
    "@supabase/supabase-js": {
      "@supabase/auth-js": "2.61.0"
    }
  }
}
