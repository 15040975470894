import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  combineLatest,
  debounce,
  delay,
  distinctUntilChanged,
  of,
  switchMap,
  timer,
} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { WorkspaceService } from './workspace.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  //#region Injections
  private readonly authService = inject(AuthService);
  private readonly workspaceService = inject(WorkspaceService);
  //#endregion

  //#region Observables
  private onLoading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.onLoading$.asObservable();
  //#endregion

  //#region Control Variables
  loading = toSignal(
    combineLatest([
      this.loading$,
      this.authService.profile$,
      this.workspaceService.onWorkspaceLoading$,
    ]).pipe(
      distinctUntilChanged(
        (previous, current) =>
          JSON.stringify(previous) === JSON.stringify(current),
      ),
      switchMap(([loading, profile, loadingWorkspace]) => {
        const showLoading = loading || loadingWorkspace;
        if (showLoading) {
          return of(true);
        }

        return of(false).pipe(delay(Math.random() * 1000));
      }),
      debounce((value) => {
        return value ? of(true) : timer(250);
      }),
    ),
  );
  //#endregion

  constructor() {}

  //#region Public Methods
  show() {
    this.onLoading$.next(true);
  }

  hide() {
    this.onLoading$.next(false);
  }
  //#endregion
}
