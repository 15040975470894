import { computed, inject, Injectable, signal } from '@angular/core';
import { WorkspaceService } from './workspace.service';
import { ServiceBase } from './service-base';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { Enums } from '@solid/types/supabase';
import { toSignal } from '@angular/core/rxjs-interop';

type WorkspaceSubscriptionResponse = {
  tier: Enums<'subscription_tier'>;
  created_at: string | null;
  cancel_at: string | null;
  canceled_at: string | null;
  current_period_start: string | null;
  current_period_end: string | null;
  next_invoice_cost: number | null;
  currency: string;
};

type StripePriceItem = {
  interval: 'month' | 'year';
  priceYearly: number;
  priceMonthly: number;
  pricePerItem: number;
  currency: string;
};

@Injectable({
  providedIn: 'root',
})
export class BillingService extends ServiceBase {
  //#region Constants
  // ! TODO: move this constants to the database or retrieve it from stripe
  // private readonly MAX_FREE_MEMBERS = 10;
  private readonly MAX_FREE_MEMBERS = 90; //Changed temporary for demo purposes for Alex
  private readonly MAX_PRO_MEMBERS = 100;
  //#endregion

  //#region Injections
  private readonly workspaceService = inject(WorkspaceService);
  //#endregion

  //#region Control Variables
  loadingTier = signal(true);
  loadingCheckoutUrl = signal(false);
  hasMaximumMembers = computed(() => {
    const tier = this.tier();
    const memberCount = this.memberCount();

    if (tier === 'free') {
      return memberCount >= this.MAX_FREE_MEMBERS;
    }

    if (tier === 'pro') {
      return memberCount >= this.MAX_PRO_MEMBERS;
    }

    if (tier === 'custom') {
      return true;
    }

    return false;
  });
  //#endregion

  //#region Data Variables
  prices = toSignal(this.getStripePrices(), { initialValue: [] });
  tier = signal<Enums<'subscription_tier'>>('free');
  maxTierMembers = computed(() => {
    const tier = this.tier();

    if (tier === 'free') {
      return this.MAX_FREE_MEMBERS;
    }

    if (tier === 'pro') {
      return this.MAX_PRO_MEMBERS;
    }

    return -1;
  });
  memberCount = toSignal(
    this.workspaceService.users$.pipe(map((users) => users.length)),
    {
      initialValue: 1,
    },
  );
  seatsLeft = computed(() => {
    const memberCount = this.memberCount();
    const maxTierMembers = this.maxTierMembers();

    return Math.max(0, maxTierMembers - memberCount);
  });
  exceedsUserLimit = computed(() => this.memberCount() > this.maxTierMembers());
  //#endregion

  constructor() {
    super();

    this.workspaceService.workspace$.subscribe({
      next: (workspace) => {
        if (!workspace) {
          this.loadingTier.set(false);
          return;
        }
        // this.loadingTier.set(true);

        this.getWorkspaceTier(workspace.id).subscribe({
          next: (response) => {
            if (response.error) {
              this.tier.set('free');
              this.loadingTier.set(false);
              return;
            }

            const tier = response.data.tier as Enums<'subscription_tier'>;
            this.tier.set(tier);
            this.loadingTier.set(false);
          },
        });
      },
    });
  }

  //#region Private Methods
  private getWorkspaceTier(workspaceId: number) {
    console.log('workspace');
    return from(
      this.supabase.client.functions.invoke(
        `get-workspace-tier?workspaceId=${workspaceId}`,
        {
          method: 'GET',
        },
      ),
    );
  }

  //#endregion

  //#region Public Methods
  getWorkspaceSubscription(
    workspaceId?: number,
  ): Observable<WorkspaceSubscriptionResponse> {
    if (!workspaceId) {
      return from(
        this.workspaceService.workspace$.pipe(
          switchMap((workspace) => {
            if (!workspace) {
              throw new Error('Error getting current workspace');
            }

            return this.getWorkspaceSubscription(workspace.id);
          }),
        ),
      );
    }

    return from(
      this.supabase.client.functions
        .invoke(`get-workspace-subscription?workspaceId=${workspaceId}`, {
          method: 'GET',
        })
        .then((response) => {
          if (response.error) {
            throw new Error('Error getting workspace subscription');
          }

          return response.data;
        }),
    );
  }

  getStripeCheckoutUrl(interval: 'month' | 'year') {
    this.loadingCheckoutUrl.set(true);

    return from(
      this.workspaceService.workspace$.pipe(
        switchMap((workspace) => {
          if (!workspace) {
            throw new Error('Error getting current workspace');
          }

          return this.supabase.client.functions
            .invoke('create-stripe-checkout', {
              method: 'POST',
              body: {
                workspaceId: workspace.id,
                interval,
                returnUr: window.location.toString(),
              },
            })
            .then((response) => {
              if (response.error) {
                throw new Error('Error creating Stripe Checkout URL');
              }

              this.loadingCheckoutUrl.set(false);
              return response.data.url;
            });
        }),
      ),
    );
  }

  getStripePortalUrl() {
    return from(
      this.supabase.client.functions
        .invoke('get-stripe-portal', {
          method: 'GET',
        })
        .then((response) => {
          if (response.error) {
            throw new Error('Error creating Stripe Checkout URL');
          }

          this.loadingCheckoutUrl.set(false);
          return response.data.url;
        }),
    );
  }

  getStripePrices() {
    return from(
      this.supabase.client.functions
        .invoke('get-stripe-prices', {
          method: 'GET',
        })
        .then((response) => {
          if (response.error) {
            throw new Error('Error getting Stripe pricing');
          }

          this.loadingCheckoutUrl.set(false);
          return response.data as StripePriceItem[];
        }),
    );
  }
  //#endregion
}
